import landing from './landing'
import forms from './forms'
import modal from './modal'
import admin from './admin/index'
import notification from './notification'
import olympiad from './olympiad/index'
import date from './date.js'

export default {
	landing,
	forms,
	modal,
	notification,
	...admin,
	...olympiad,
	date,
	fromNumber: ' of',
	loading: 'Loading...',
	test: 'Test',
	show: 'Show',
	category: 'Category of results',
	top: 'TOP 10 in categories',
	saveResults: 'Download results',
	results: 'Results',
	checkoutResult: `Get familiarized with the <strong> results </strong> of the Olympiad`,
	templates: 'Job templates (by age group and difficulty level)',
	mail: 'Email',
	password: 'Password',
	search: 'Search',
	logIn: 'Sign in',
	lng: {
		ru: 'Russian',
		uk: 'Ukrainian',
		pl: 'Polish',
		en: 'English',
		ro: 'Romanian',
		az: 'Azerbaijani',
		hy: 'Armenian',


	},
	currency: {
		full: {
			PLN: 'Zloty',
			USD: 'Dollar',
			UAH: 'UAH',
			RUB: 'Rubles',
			RON: 'Ley',
			AZN: 'AZN',
			AMD: 'Dram',

		},
		short: {
			PLN: 'ZL',
			USD: 'USD',
			UAH: 'UAH',
			RUB: 'RUB',
			RON: 'RON',
			AZN: 'AZN',
			AMD: 'Dram',

		},
	},
	demoLesson: 'Demo-lesson',
	registration: 'Registration',
	startOlympic: 'Start Olympiad',
	franchise: 'Franchise',
	personalArea: 'Personal area',
	menu: 'Menu',
	backDown: 'Go back',
	giveAgree: 'I agree',
	goHome: 'Go home',
	reload: 'Update',
	delete: 'Delete',
	save: 'Save',
	errors: {
		status: {
			404: `<p>The page is not found.</p> <p>Read from head side.</p>`,
			500: `<p>Find out from the head side.</p> <p>Update the page.</p>`,
			503: `<p>The server has become a grave.</p> <p>Update the page.</p>`,
		},
	},
	condition: {
		fondy: 'Terms and conditions',
		title: 'Please read the <strong> Event Terms </strong> before paying',
		description: 'Likewise, the constant quantitative growth and scope of our activity largely determines the creation of directions for progressive development. We should not, however, forget that the beginning of daily work to form a position largely determines the creation of forms of development. Diverse and rich experience strengthening and developing the structure require us to analyze significant financial and administrative conditions. Likewise, the beginning of the day-to-day work on the formation of a position requires the identification and clarification of further directions of development. Diverse and rich experience, constant information and propaganda support of our activities allows us to carry out important tasks for the development of new proposals. that constant quantitative growth and the scope of our activity plays an important role in the formation of the personnel training system, meets urgent needs. <br/> ' +
			'<br/>' +
			'Diverse and rich experience, strengthening and development of the structure requires the definition and clarification of further development directions. Diverse and rich experience of the implementation of the planned targets contributes to the preparation and implementation of appropriate conditions for activation. On the other hand, the existing structure of the organization requires the definition and refinement of the development model. <br/>' +
			'<br/>' +
			'Comrades, the existing structure of the organization contributes to the preparation and implementation of further directions of development. The task of the organization, in particular the strengthening and development of the structure, plays an important role in the formation of significant financial and administrative conditions. Comrades, further development of various forms of activity allows us to assess the importance of systems of mass participation. <br/> ' +
			'<br/>' +
			'Diverse and rich experience in strengthening and developing the structure allows us to carry out important tasks for the development of further directions of development. Likewise, the further development of various forms of activity largely determines the creation of systems of mass participation. <br/>' +
			'<br/>' +
			'The task of the organization, in particular the constant information and propaganda support of our activities, allows us to carry out important tasks to develop directions for progressive development. Diverse and rich experience, the existing structure of the organization plays an important role in shaping further directions of development. <br/>' +
			'<br/>' +
			'Comrades, further development of various forms of activity requires the definition and clarification of the directions of progressive development. Likewise, the further development of various forms of activity requires the definition and refinement of the development model. The task of the organization, in particular strengthening.',
	},
}
