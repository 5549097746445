import ErrorPage from "components/ErrorPage";
import React, { lazy, memo, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import RouteWrapper from "./routes/RouteWrapper";
import { availableNewVersion, contestId, lastContestId, networkError, TIMEZONE } from "./api/apollo/state";
import { useReactiveVar } from "@apollo/client";
import NewVersionModal from "./components/NewVersionModal";
import { ConfigurationContestStateEnum, useConfigurationMinQuery } from "./api/generated";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { storage } from "utils/localStorage";
import { LANGUAGES } from "./react_constants";
// import Landing from 'pages/Landing/Landing'
// import Registration from './pages/Registration'
// @ts-ignore
const Landing = lazy(() => import("pages/Landing/Landing"));
const Registration = lazy(() => import("pages/Registration"));
const Admin = lazy(() => import("pages/Admin"));
const Olympiad = lazy(() => import("pages/Olympiad"));
const Result = lazy(() => import("pages/Result"));

function App() {
  const { pathname } = useLocation();
  const [t, { language: lang }] = useTranslation();
  const isNewVersion = useReactiveVar(availableNewVersion);
  const error = useReactiveVar(networkError);
  const { data } = useConfigurationMinQuery({
    variables: { lang },
    // onCompleted(res) {
    // 	if (res?.configuration?.contestState !== ConfigurationContestStateEnum.Finished) {
    // 		lastContestId(res?.configuration?.lastContest?.id)
    // 	}
    // },
  });

  useEffect(() => {
    TIMEZONE(Intl.DateTimeFormat().resolvedOptions().timeZone);
    navigator &&
      navigator.serviceWorker &&
      navigator.serviceWorker
        ?.getRegistrations()
        .then((registrationsArray) => {
          registrationsArray && registrationsArray[0]?.update();
        })
        .catch((e) => console.error("console error: ====> ", e.message));
  }, [pathname]);

  useEffect(() => {
    if (data?.configuration?.countryCode && !storage("lng")) {
      let code = data.configuration.countryCode.toLowerCase();
      let countryCode = code === "ua" ? "uk" : LANGUAGES.includes(code) ? code : "en";
      storage("lng", countryCode);
      i18next.changeLanguage(countryCode);
    }
  }, [data]);

  if (error) {
    console.log("error", error);

    return <ErrorPage status={503} />;
  }

  return (
    <div className="App">
      {isNewVersion && <NewVersionModal />}

      <Switch>
        <Route path={"/"} exact component={Landing} />
        <Route path={"/payment/:id"} component={Landing} />
        <Route path={"/result"} component={Result} />
        <Route path={"/registration"} component={Registration} />
        <Route path={"/admin/"} exact render={() => <Redirect to={"/admin/general"} />} />
        <RouteWrapper component={Admin} path={"/admin/:tab"} needToken={true} />
        <RouteWrapper component={Olympiad} path={"/olympiad/:mode"} whenRedirect={process.env.NODE_ENV === "development" ? false : data?.configuration?.contestState === ConfigurationContestStateEnum.Finished} />
        <RouteWrapper component={Olympiad} path={"/olympiad"} whenRedirect={process.env.NODE_ENV === "development" ? false : data?.configuration?.contestState === ConfigurationContestStateEnum.Finished} />
        <Route path={"*"} render={() => <ErrorPage status={404} />} />
      </Switch>
    </div>
  );
}

export default memo(App);
