import React, { FC, memo } from "react";
import classNames from "classnames/bind";
import s from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Button from "components/Button";
import logo from "../../assets/image/logo_color.svg";
import Footer from "components/Footer";
import { networkError } from "../../api/apollo/state";
import { CalcIcon, LampIcon, LineEllipseIcon } from "../../assets/icons";

const cx = classNames.bind(s);

type ErrorPage = {
  status?: 404 | 500 | 503;
  message?: string | null;
};

const ErrorPage: FC<ErrorPage> = ({ status = 404, message = null }) => {
  const [t] = useTranslation();
  const { push } = useHistory();
  const handleClick = () => {
    networkError(null);

    if (status === 404) {
      push("/");
    } else {
      window.location.reload();
    }
  };

  return (
    <div className={cx("ErrorPageWrap")}>
      <div className={cx("ErrorPage")}>
        <header>
          <div>
            <Link to={"/"} className={cx("Logo")}>
              <img src={logo} alt={"logo"} />
            </Link>
          </div>
        </header>
        <div className={cx("ErrorPageContent")}>
          <div className={cx("ErrorPageStatus")}>{status}</div>
          <div className={cx("ErrorPageDescription")}>
            {message ? <p>{message}</p> : <p dangerouslySetInnerHTML={{ __html: t("errors.status." + status) }} />}

            <Button color={"pink"} onClick={handleClick} label={t(status !== 404 ? "reload" : "goHome")} />
          </div>
        </div>

        <Footer />
      </div>

      <Icons />
    </div>
  );
};
export default memo(ErrorPage);
export const Icons = memo(
  (): JSX.Element => (
    <>
      <CalcIcon classNames={"i-calc"} opacity={".6"} x={"14%"} y={"37%"} rotate={-23} width={48} height={48} fill={"var(--pink)"} />
      <CalcIcon classNames={"i-calc"} opacity={".6"} x={"84%"} y={"47%"} rotate={33} width={48} height={48} fill={"var(--pink)"} />
      <LampIcon classNames={"i-lamp"} opacity={".7"} x={"20%"} y={"5%"} rotate={8} width={48} height={48} fill={"var(--pink)"} />
      <LampIcon classNames={"i-lamp"} opacity={".7"} x={"80%"} y={"75%"} rotate={180} width={48} height={48} fill={"var(--pink)"} />

      <LineEllipseIcon classNames={"i-line"} opacity={".4"} x={"22%"} y={"75%"} rotate={8} width={48} height={48} fill={"var(--pink)"} />
      <LineEllipseIcon classNames={"i-line"} opacity={".4"} x={"82%"} y={"15%"} rotate={20} width={48} height={48} fill={"var(--pink)"} />
    </>
  )
);
